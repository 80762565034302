module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"Page":{"where":"categoryName: \"documentation\""},"Comment":{"exclude":true},"Menu":{"exclude":true},"MenuItem":{"exclude":true},"Taxonomy":{"exclude":true},"UserRole":{"exclude":true},"MediaItem":{"exclude":true,"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Post":{"limit":1},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"url":"https://trafft.com/graphql","develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":false},"debug":{"preview":true,"timeBuildSteps":true,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"typePrefix":"Wp","queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icons/icon-512x512.png","icons":[{"src":"/images/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/images/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/images/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/images/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/images/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a798809f107a0bedd1ad6ebb5efeae2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WFQMRGC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
