require('./src/css/global.scss');

const Gleap = require("gleap");

function initScripts() {
  if (window.isScriptLoaded) {
    return false;
  }

  window.isScriptLoaded = true;

  if (window.location.href.includes('one.com/')) {
    return;
  }
}

function loadScripts(event) {
  initScripts();
  event.currentTarget.removeEventListener(event.type, loadScripts);
}

exports.onClientEntry = function () {
  Gleap.initialize("qTwmjufNmPXyIAesxuRnhSxBONPR8rEu");

  document.onreadystatechange = function () {
    if (document.readyState !== 'loading') {
      setTimeout(initScripts, 4000);
    }
  };

  document.addEventListener('scroll', loadScripts);
  document.addEventListener('mousemove', loadScripts);
  document.addEventListener('touchstart', loadScripts);
};
